<template>
    <section class="validation">
        <div>
            <div class="page-header">
                <h3 class="page-title">
                    Add Event
                </h3>
                <div class="button-group">
                    <button type="button" class="btn btn-info" @click="goBack()">
                        <i class="mdi mdi-keyboard-backspace"></i> Back</button>
                </div>
            </div>


            <div class="row">
                <div class="col-lg-7 m-auto">
                    <div class="card">
                        <div class="card-body">
                            <!-- <h4 class="card-title mb-4">Edit Audio Category</h4> -->
                            <b-form @submit.stop.prevent="onSubmit">
                                <!-- <b-form-group id="example-input-group-1" label="Category Name"
                                    label-for="example-input-1">
                                    <b-form-input id="example-input-1" name="example-input-1"
                                        v-model="$v.form.name.$model"
                                        :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                                        aria-describedby="input-1-live-feedback"></b-form-input>
                                </b-form-group> -->

                                <div class="container-fluid my-4">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <!-- <div class="file-box" style="padding:10px 20px; cursor: default;"> -->
                                            <b-form-group id="example-input-group-1" label="Event Title"
                                                label-for="example-input-1">
                                                <b-form-input id="example-input-1" name="example-input-1"
                                                    v-model="$v.form.name.$model" placeholder="Enter Event Title"
                                                    :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                                                    aria-describedby="input-1-live-feedback"></b-form-input>
                                            </b-form-group>
                                            <div v-if="$v.form.name.$error" class="invalid-feedback">
                                                <div v-if="!$v.form.name.required">Title is required</div>
                                                <div v-if="!$v.form.name.maxLength">Maximum limit 100
                                                    characters</div>
                                            </div>

                                            <!-- </div> -->
                                        </div>
                                        <div class="col-md-12">
                                            <label for="example-datepicker">Choose a date</label>
                                            <b-form-datepicker id="example-datepicker" v-bind:min="eventDate" v-model="$v.form.date.$model"
                                                :state="$v.form.date.$dirty ? !$v.form.date.$error : null" class="mb-2">
                                            </b-form-datepicker>
                                            <div v-if="$v.form.date.$error" class="invalid-feedback">
                                                <div v-if="!$v.form.date.required">Event Date is required</div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <label for="example-start-timepicker">Choose Start Time</label>
                                            <b-form-timepicker id="example-start-timepicker" locale="en" reset-button
                                                v-model="$v.form.start_time.$model"
                                                :state="$v.form.start_time.$dirty ? !$v.form.start_time.$error : null"
                                                class="mb-2"></b-form-timepicker>
                                            <div v-if="$v.form.start_time.$error" class="invalid-feedback">
                                                <div v-if="!$v.form.start_time.required">Event start time is required</div>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <label for="example-end-timepicker">Choose End Time</label>
                                            <b-form-timepicker id="example-end-timepicker" locale="en" reset-button
                                                v-model="$v.form.end_time.$model"
                                                :state="$v.form.end_time.$dirty ? !$v.form.end_time.$error : null"
                                                class="mb-2">
                                            </b-form-timepicker>
                                            <div v-if="$v.form.end_time.$error" class="invalid-feedback">
                                                <div v-if="!$v.form.end_time.required">Event end time is required</div>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <label for="example-datepicker2">Choose date to notify users</label>
                                            <b-form-datepicker id="example-datepicker2" v-bind:min="notificationDate" v-model="$v.form.notify_date.$model"
                                                :state="$v.form.notify_date.$dirty ? !$v.form.notify_date.$error : null"
                                                class="mb-2">
                                            </b-form-datepicker>
                                            <div v-if="$v.form.notify_date.$error" class="invalid-feedback">
                                                <div v-if="!$v.form.notify_date.required">Notification date is required
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <label for="example-start-timepicker">Choose time to notify users</label>
                                            <b-form-timepicker id="example-start-timepicker" locale="en" reset-button
                                                v-model="$v.form.notify_time.$model"
                                                :state="$v.form.notify_time.$dirty ? !$v.form.notify_time.$error : null"
                                                class="mb-2"></b-form-timepicker>

                                            <div v-if="$v.form.notify_time.$error" class="invalid-feedback">
                                                <div v-if="!$v.form.notify_time.required">Notification time is required
                                                </div>
                                            </div>
                                        </div>


                                        <div class="col-md-12">
                                            <!-- <div class="file-box" style="padding:10px 20px; cursor: default;"> -->
                                            <b-form-group id="example-input-group-1" label="Event Link"
                                                label-for="example-input-link">
                                                <b-form-input id="example-input-link" name="example-input-link"
                                                    v-model="$v.form.link.$model" placeholder="Enter Event Link"
                                                    :state="$v.form.link.$dirty ? !$v.form.link.$error : null"
                                                    aria-describedby="input-1-live-feedback"></b-form-input>
                                            </b-form-group>
                                            <div v-if="$v.form.link.$error" class="invalid-feedback">
                                                <div v-if="!$v.form.link.required">Event link is required</div>
                                            </div>
                                            <!-- </div> -->
                                        </div>


                                        <div class="col-md-12">
                                            <div class="file-box d-flex" @click="$refs.image.click()">
                                                <div class="col-md-1">
                                                    <i class="mdi mdi-cloud-upload" style="font-size:50px"></i>
                                                    <input class="ml" type="file" @change="onAudioImageChange" ref="image"
                                                        style="display: none" />
                                                </div>
                                                <div class="col-md-8">
                                                    <div class="file-text-box">
                                                        <span>Upload</span><br />
                                                        <small>Upload event image here</small>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <img v-if="image_url !== ''" :src="image_url"
                                                        class="img-thumbnail rounded-circle" alt="Image"
                                                        style="height: 65px; width: 65px; margin-top:8px" />
                                                </div>
                                            </div>
                                            <p class="err" v-if="image_error != ''">{{ image_error }}</p>
                                        </div>

                                        <div class="col-md-12 mt-3">
                                            <b-form-group id="example-input-group-desc" label="Description"
                                                label-for="example-input-desc">
                                                <b-form-textarea id="example-input-desc" name="example-input-desc"
                                                    v-model="$v.form.description.$model"
                                                    :state="$v.form.description.$dirty ? !$v.form.description.$error : null"
                                                    aria-describedby="input-1-live-feedback"
                                                    placeholder="Enter something..." rows="3" max-rows="6">
                                                </b-form-textarea>
                                            </b-form-group>
                                            <div v-if="$v.form.description.$error" class="invalid-feedback">
                                                <div v-if="!$v.form.description.required">Description is required</div>
                                            </div>
                                        </div>
                                    </div>



                                    <div class="text-right mt-3">
                                        <b-button :disabled='is_loading' type="submit" variant="success"
                                            class="btn believe-btn btn-block">
                                            <i
                                                :class="is_loading ? 'circle-loader' : 'mdi mdi-file-check btn-icon-prepend'"></i>
                                            Submit
                                        </b-button>
                                    </div>
                                </div>
                            </b-form>
                            <vue-snotify></vue-snotify>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { json } from 'body-parser';
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import api from "../../../config/api.js";
// import simpleSnotifysuccess from '@/components/alerts/snotify/simpleSnotifysuccess'
export default {
    name: 'AudioCategoryAddModal',
    mixins: [validationMixin],
    data() {
        return {
            msg: "",
            is_loading: false,
            image_error: "",
            image_url: "",
            form: {
                type: "Upcoming",
                id: null,
                description: null,
                name: null,
                image: "",
                link: null,
                description: null,
                date: null,
                notify_date: null,
                start_time: null,
                notify_time: null,
                end_time: null,
            }
        }
    },

    computed: {
        eventDate() {
            return new Date().toISOString().split('T')[0];
        },
        notificationDate() {
            return new Date().toISOString().split('T')[0];
        },
    },

    components: {
        // simpleSnotifysuccess
    },
    validations: {
        form: {
            name: {
                required,
                maxLength: maxLength(100)
            },
            date: {
                required,
            },
            start_time: {
                required,
            },
            notify_time: {
                required,
            },
            notify_date: {
                required,
            },
            end_time: {
                required,
            },
            link: {
                required,
            },
            description: {
                required,
            },
            // image: {
            //     required,
            // },
        }
    },

    methods: {
        goBack() {
            let prev_url = `/events/list/`;
            this.$router.push({ path: prev_url });
        },
        onAudioImageChange(e) {
            this.image = "";
            const file = e.target.files[0];
            var status = this.checkFileType(file, 'image1')
            if (status) {
                this.image_error = "";
                this.form.image = file;
                this.image_url = URL.createObjectURL(file)
            }
        },

        checkFileType(file, num) {
            var types = ['image/jpeg', 'image/png', 'image/jpg']
            var msg = "The image must be a file of type: jpeg, jpg, png";
            if (!types.includes(file.type)) {
                if (num == 'image1') {
                    this.image_error = msg
                    this.form.image = "";
                }
                return false;
            }
            return true;
        },

        async onSubmit() {
            console.log(this.$v.form);
            this.$v.form.$touch()
            if (this.$v.form.$anyError || this.image_error != "") {
                return false;
            }
            if (this.image_url != "") {
                try {
                    this.is_loading = true;
                    // const obj = this.$store.getters['getEditFormData'];
                    let endpoint = "/admin/add-event";
                    const formData = new FormData();
                    formData.append("name", this.form?.name);
                    formData.append("description", this.form?.description);
                    formData.append("start_time", this.form?.start_time);
                    formData.append("notify_time", this.form?.notify_time);
                    formData.append("end_time", this.form?.end_time);
                    formData.append("type", this.form?.type);
                    formData.append("link", this.form?.link);
                    formData.append("date", this.form?.date);
                    formData.append("notify_date", this.form?.notify_date);
                    formData.append("image", this.form?.image);

                    let result = await api.post(endpoint, formData);
                    if (result.status == 200) {
                        const msg = await result.data.message;
                        this.$store.dispatch('setMessage', { msg });
                        // await this.$store.dispatch(`${type}/all`);
                        let prev_url = `/events/list/`;
                        this.$router.push({ path: prev_url });
                        this.is_loading = false;
                    }
                } catch (error) {
                    // this.progress = 0;
                    let err = error.response.data.message;
                    this.$store.dispatch('setMessage', { err });
                    // this.$root.$refs.Category.showSnotifyError()
                    // this.$toast.error(this.error);
                }
            } else {
                this.image_error = (this.image_url == "" && this.image_error == "") ? "Image is required" : this.image_error
            }
        },

    },




}
</script>

<style>
footer.b-time-footer {
    padding: 0px !important;
    background-color: #fff !important;
}
</style>